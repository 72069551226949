import React, { useState } from 'react';
import logo from './logo.svg';
import './App.css';
import ReCAPTCH from "react-google-recaptcha";

function App() {
  const [siteKey,setSiteKey] = useState('6Lf2FuYjAAAAAL5BdknV_qecUd8y68xcHzicWsnT');
  return (
    <div className="App">
     
      <div>
        <h1>Test</h1>
        <ReCAPTCH
					sitekey={siteKey}
					onChange={(value:string|null) => {
						console.log('Captcha value:', value);
					}}
          />
      </div>
    </div>
  );
}

export default App;
